<template>
  <v-container fluid>
    <fe-swisstex v-if="$store.state.emp.cod_empresa == 381" />
    <fe-conab v-if="$store.state.emp.cod_empresa == 383" />
    <fe-unique v-if="$store.state.emp.cod_empresa == 380" />
    <fe-ib-marker v-if="$store.state.emp.cod_empresa == 337" />
    <fe-txool v-if="$store.state.emp.cod_empresa == 445" />
    <fe-personal-soft v-if="$store.state.emp.cod_empresa == 398" />
    <div
      v-if="
        $store.state.emp.cod_empresa != 381 &&
        $store.state.emp.cod_empresa != 383 &&
        $store.state.emp.cod_empresa != 380 &&
        $store.state.emp.cod_empresa != 337 &&
        $store.state.emp.cod_empresa != 445 &&
        $store.state.emp.cod_empresa != 398
      "
    >
      <lottie
        :options="defaultOptions"
        :height="700"
        :width="700"
        v-on:animCreated="handleAnimation"
      />
    </div>
  </v-container>
</template>

<script>
import Lottie from "vue-lottie";
import * as animationData from "../../animations/page_not_found.json";

import feSwisstex from "../Facturacion/Swisstex/FeSwisstex.vue";
import feConab from "../Facturacion/Conab/FeConab.vue";
import feUnique from "../Facturacion/Unique/FeUnique.vue";
import feIbMarker from "../Facturacion/IBMaker/FeIBMarker.vue";
import FeTxool from "./Txool/FeTxool.vue";
import FePersonalSoft from "./PersonalSoft/FePersonalSoft.vue";

export default {
  data: () => ({
    defaultOptions: { animationData: animationData },
    animationSpeed: 1,
  }),
  components: {
    feSwisstex,
    feConab,
    feUnique,
    feIbMarker,
    FeTxool,
    FePersonalSoft,
    Lottie,
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
  },
};
</script>